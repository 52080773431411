const $analyseLaunched = document.querySelector<HTMLInputElement>('input#analyse-launched[type="checkbox"]'),
	$urlInput = document.querySelector<HTMLInputElement>("#url"),
	$emailInput = document.querySelector<HTMLInputElement>("#address")
;
if (!$urlInput) {
	throw new Error("MISSING_INPUT_URL");
}
if (!$emailInput) {
	throw new Error("MISSING_EMAIL");
}
if (!$analyseLaunched) {
	throw new Error("MISSING_ANALYSE_LAUNCHED");
}
const form = $urlInput.form,
	part1 = form?.querySelector(".part1")
;
if (!form) {
	throw new Error("MISSING_FORM");
}

const httpsRegex = /^(https?:\/\/)/,
	wwwStartRegex = /(^www\.|^www$)/
;
let submitted = false;
// Prevent double submissions
form.addEventListener('submit', function (e) {
	if (submitted) {
		e.preventDefault();
		e.stopImmediatePropagation();
	} else {
		const emailValue = $emailInput.value;
		if (emailValue) {
			localStorage.setItem("savedEmail", emailValue); // Stocker l'email
		}

		console.debug('Submitted !')
		const submit = this.querySelector<HTMLButtonElement>('button[type="submit"]');
		if (submit) {
			submit.disabled = true;
		}
	}
	submitted = true;
});



function init() {
	if (!$emailInput || !$urlInput) return;


	// Préremplir l'email si déjà stocké
	const savedEmail = localStorage.getItem("savedEmail");
	if (savedEmail) {
		$emailInput.value = savedEmail;

		const consentCheck = document.querySelector<HTMLInputElement>("#consent");
		if (consentCheck && !consentCheck.checked) {
			consentCheck.click();
		}
	}
	$urlInput.focus();


	setTimeout(() => {
		// noinspection SpellCheckingInspection
		part1?.classList.add("enablecontrol");
	}, 3000);
}





/**
 * Gérer la touche entrée en fonction des cas
 */
form.addEventListener("keydown", function (event) {
	// Vérifier si la touche pressée est la touche "Entrée" (code 13)
	if (event.code.toLowerCase() !== "enter" || $analyseLaunched.checked) return;


	const consentChecked = document.querySelector<HTMLInputElement>("#consent")?.checked ?? false;
	if (!consentChecked) {
		if ($urlInput.checkValidity() && !$analyseLaunched.checked) {
			$analyseLaunched.click();
		}
		event.preventDefault(); // Empêcher l'envoi du formulaire par la touche Entrée
	}
});

/**
 * Mettre le focus sur le champ mail dès que l'URL est rempli
 */
$analyseLaunched.addEventListener("change", function (event) {
	console.debug(event);
	$emailInput.focus();
});

/**
 * URL input helper
 * ajout automatique du protocol
 */
let timeout:ReturnType<typeof setTimeout>|null = null;
function debounceUrlFormat(delay:number) {
	if (timeout !== null) {
		clearTimeout(timeout);
		timeout = null;
	}

	const urlFormat = function urlFormat() {
		if (!$urlInput) return;

		const url = $urlInput.value;
		if (url !== "" && !httpsRegex.test(url)) {
			$urlInput.value = `https://${url}`;
			enableControl();
		}
	}
	timeout = setTimeout(urlFormat, delay);
}

$urlInput.addEventListener("keyup", function () {
	debounceUrlFormat(1000);
});

/**+
 * ajout des boutons raccourcis pour la saisie
 * @param id
 * @param texte
 * @param title
 */
function createButton(id:string, texte:string, title:string): HTMLButtonElement {
	const button = document.createElement("button"); // Créer un élément <button>
	button.id = id; // Définir l'ID du bouton
	button.textContent = texte; // Ajouter le texte du bouton
	button.type = "button"; // Définir le type de bouton
	button.title = title;
	return button;
}

if (!part1) {
	throw new Error('MISSING_PART1');
}

const btnHttps = createButton('btnHttps', 'https://', 'Ajouter le protocol');
btnHttps.addEventListener('click', ajouterHttps);
part1.appendChild(btnHttps);

const btnWww = createButton('btnWww', 'www', 'Ajouter le prefix "www"');
btnWww.addEventListener('click', ajouterWww);
part1.appendChild(btnWww);

const suffixes = new Set<string>(["fr", "com", "org", "net", "eu"]),
	suffixesButtons: HTMLButtonElement[] = []
;
for (const suffix of suffixes) {
	const btn = createButton(`btn${suffix}`, `.${suffix}`, `Ajouter le suffixe ".${suffix}"`)
	btn.addEventListener('click', function () {
		ajouterSuffix(suffix);
	});
	btn.classList.add('suffix');
	part1.appendChild(btn);
	suffixesButtons.push(btn);
}

/**
 * Vérifie les changements dans l'input URL
 */
function enableControl() {
	if (!$urlInput) return;

	const url = $urlInput.value;

	// Vérifier pour https://
	btnHttps.disabled = httpsRegex.test(url);

	// Vérifier pour www
	const domain = url.replace(httpsRegex, '').split('/').at(0); // Extrait uniquement le domaine
	btnWww.disabled = domain?.startsWith("www.") ?? false;

	// Vérifier pour .com
	for (const suffixBtn of suffixesButtons) {
		suffixBtn.disabled = domain?.endsWith(suffixBtn.textContent ?? '') ?? false;
	}
}
$urlInput.addEventListener("input", enableControl);
$urlInput.addEventListener("focus", enableControl);


/**
 * Fonction pour ajouter https://
 */
function ajouterHttps() {
	if (!$urlInput) return;

	let url = $urlInput.value;
	if (!httpsRegex.test(url)) {
		$urlInput.value = `https://${url}`;
	}

	// Placer le curseur après "https://"
	setTimeout(() => {
		$urlInput.focus();
		$urlInput.setSelectionRange(8, 8); // Positionne après "https://"
	}, 10);
}

/**
 * Fonction pour ajouter www
 */
function ajouterWww() {
	if (!$urlInput) return;

	ajouterHttps();

	let url = $urlInput.value.trim(),
		positionCurseur = url.match(/^(https?:\/\/)/)?.at(0)?.length ?? 0
	;
	if (!/www\./i.test(url.replace(httpsRegex, ''))) {
		url = url.replace(httpsRegex, '$1www.');
	}

	updateUrl(url, positionCurseur + 4);
}

/**
 * Fonction pour ajouter ou modifier le suffixe
 * @param suffix
 */
function ajouterSuffix(suffix:string) {
	if (!$urlInput) return;

	ajouterHttps();

	let url = $urlInput.value.trim();
	if (!url.replace(httpsRegex, '')) {
		const positionCurseur = url.length;
		url += `.${suffix}`;
		updateUrl(url, positionCurseur);
		return;
	}

	const currentUrl = $urlInput.value.trim(),
		additionalDot = currentUrl.endsWith('.') ? '.' : ''
	;
	let _url:URL;
	try {
		_url = new URL(currentUrl.replace(/\.$/, ''));
	} catch (e) {
		console.error(e);
		return;
	}

	let originalCleanedDomain = _url.hostname;
	let hadWww = false;
	if (wwwStartRegex.test(originalCleanedDomain)) {
		hadWww = true;
		originalCleanedDomain = originalCleanedDomain.replace(wwwStartRegex, ''); // Retirer "www." pour l'analyse
	}

	// Vérifier si le domaine contient déjà un suffixe
	const currentSuffixRegex = /\.[a-zA-Z]{2,}$/;
	if (!currentSuffixRegex.test(originalCleanedDomain)) {
		_url.hostname += `${additionalDot}.${suffix}`; // Ajouter si aucun suffixe
	} else {
		_url.hostname = originalCleanedDomain.replace(currentSuffixRegex, `${additionalDot}.${suffix}`); // Remplacer l'ancien suffixe
		if (hadWww) {
			_url.hostname = `www.${_url.hostname}`;
		}
	}


	/**
	 * position curseur = protocol + domain + path - (si input invalide : longueur du suffixe et le point)
	 */
	const positionCurseur = _url.protocol.length + 2 + _url.host.length - ($urlInput.checkValidity() ? 0 : 1) * (suffix.length + 1);
	updateUrl(_url, positionCurseur);
}

function updateUrl(url: URL|string, positionCurseur: number) {
	if (!$urlInput) return;

	if (typeof url !== 'string') {
		const slashEnd = $urlInput.value.endsWith('/'),
			newUrl = url.toString()
		;
		$urlInput.value = slashEnd ? newUrl : $urlInput.value = newUrl.replace(/\/$/i, '');
	} else {
		$urlInput.value = url;
	}

	setTimeout(() => {
		$urlInput.focus();
		$urlInput.setSelectionRange(positionCurseur, positionCurseur);
	}, 10);
}



init();
